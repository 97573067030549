/** @jsx jsx **/
import { jsx } from 'theme-ui'
import { FC } from 'react'
import TradeProgramTemplate from '~/templates/page-trade-program'

type Props = {}

const TradeProgramPage: FC<Props> = () => {
  return <TradeProgramTemplate />
}

export default TradeProgramPage
