/** @jsx jsx **/
import { jsx, Grid, Box, Heading, Container, Text, Image } from 'theme-ui'
import { FC, useState } from 'react'
import Layout from '~/components/layout'
import theme from '~/gatsby-plugin-theme-ui'
import { graphql, useStaticQuery } from 'gatsby'
import MarkdownIt from '~/components/MarkdownIt'
import BaseTypo from '~/components/base-typo'
import { hubspotFormIds } from '~/common/hubspot'
import { HubspotForm } from '~/utils/hubspotForm'
import useMediaQuery from '~/hooks/use-media-query'
import { Button } from '~/components/button'
import tradeImage from '~/images/Montecito.png'
import HeadingV2 from '~/componentsV2/heading'
import { ImageDataLike, getImage, GatsbyImage } from 'gatsby-plugin-image'
import { H1, H3, Paragraph } from '~/styled/components/typography'

type Props = {}

type QueryResult = {
  pageData: {
    frontmatter: {
      title: string
      welcomeMessage: string
      tradeTitle: string
      benefitsOpener: string
      tradeBenefits: Array<{
        benefit: string
      }>
      salut: string
      cards: {
        title: string
        description: string
        image: ImageDataLike
      }[]
    }
    html: string
  }
}

const TradeProgramTemplate: FC<Props> = () => {
  const query = useStaticQuery<QueryResult>(graphql`
    query {
      pageData: markdownRemark(frontmatter: { templateKey: { eq: "trade-membership" } }) {
        frontmatter {
          title
          welcomeMessage
          tradeTitle
          benefitsOpener
          tradeBenefits {
            benefit
          }
          salut
          cards {
            title
            description
            image {
              childImageSharp {
                gatsbyImageData(
                  width: 472
                  height: 286
                  placeholder: BLURRED
                  formats: [AUTO, WEBP]
                )
              }
            }
          }
        }
        html
      }
    }
  `)
  const isMobile = useMediaQuery({
    query: breakpoints => `(max-width: ${breakpoints[2]})`,
  })

  const [showForm, setShowForm] = useState(false)
  const title = query.pageData.frontmatter.title
  const welcomeMessage = query.pageData.frontmatter.welcomeMessage
  const tradeTitle = query.pageData.frontmatter.tradeTitle
  const benefitsOpener = query.pageData.frontmatter.benefitsOpener
  const tradeBenefits = query.pageData.frontmatter.tradeBenefits
  const content = query.pageData.html
  const salut = query.pageData.frontmatter.salut
  const cards = query.pageData.frontmatter.cards

  return (
    <Layout headerVariant="default" title={title} description={title}>
      <Container
        sx={{
          maxWidth: 1500,
          my: [theme.space.l1, null, theme.space.xl2],
        }}
      >
        <HeadingV2
          sx={{
            textAlign: ['center', null, 'left'],
            mb: ['17px', null, '28px'],
            borderBottom: ['none', null, '1px solid #E6E6E6'],
            pb: '20px',
            textTransform: 'uppercase',
          }}
        >
          {title}
        </HeadingV2>
        <Grid
          columns={['1fr', '1fr 1fr']}
          gap={theme.space.gridGap3}
          sx={{
            mb: ['30px', '60px'],
          }}
        >
          <Box sx={{ order: [`${showForm === true ? 1 : 0}`, 0] }}>
            <Box
              sx={{
                maxWidth: 630,
                h3: {
                  fontSize: '26px',
                  fontWeight: 400,
                  color: '#2B2828',
                  fontFamily: 'Cormorant Garamond, Times New Roman, serif',
                },
                p: {
                  fontSize: '16px',
                  fontFamily: 'Roboto, Helvetica, "Helvetica Neue", sans-serif',
                  color: '#666666',
                  lineHeight: 2,
                },
                ul: {
                  fontSize: '16px',
                  fontFamily: 'Roboto, Helvetica, "Helvetica Neue", sans-serif',
                  mb: '50px',
                  color: '#666666',
                },
                li: {
                  lineHeight: 2.5,
                },
              }}
            >
              <Box
                sx={{
                  display: [`${showForm === true ? 'none' : 'block'}`, 'block'],
                }}
              >
                <Text variant="xsmall" sx={{ fontSize: '16px !important', lineHeight: 2 }}>
                  {welcomeMessage}
                </Text>
                <Box sx={{ display: ['block', 'none'], mt: 20, mb: 50 }}>
                  <Button
                    as="button"
                    label="Join Here"
                    sx={{ width: '100%' }}
                    onClick={() => setShowForm(!showForm)}
                  />
                </Box>
                <Box>
                  <Heading
                    as="h3"
                    variant="title2"
                    sx={{
                      textAlign: 'left',
                      mb: [theme.space.l1, null, '26px'],
                      mt: '45px',
                    }}
                  >
                    {tradeTitle}
                  </Heading>
                  <Text variant="xsmall" sx={{ p: { mb: '13px !important' } }}>
                    {benefitsOpener}:
                  </Text>
                  <ul>
                    {tradeBenefits.map(item => {
                      return <li>{item.benefit}</li>
                    })}
                  </ul>
                </Box>
              </Box>
              <Box sx={{ display: [`${showForm === false ? 'none' : 'block'}`, 'block'] }}>
                <MarkdownIt content={content} />
              </Box>
              <Box pt="10px" pb="35px">
                {salut}
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              display: [`${showForm === false ? 'none' : 'block'}`, 'block'],
              order: [`${showForm === true ? 0 : 1}`, 1],
            }}
          >
            <HubspotForm formId={String(hubspotFormIds.tradePortal)} />
          </Box>
        </Grid>

        <Grid
          columns={['1fr 1fr', '1fr 1fr 1fr']}
          gap={[theme.space.gridGap2, theme.space.gridGap3]}
        >
          {cards.map(card => {
            const image = getImage(card.image)
            return (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  color: '#2B2828',
                  h3: {
                    fontWeight: 400,
                    mb: 2,
                  },
                  p: {
                    fontWeight: '400',
                    mt: 0,
                    mb: 0,
                  },
                }}
              >
                {image && (
                  <GatsbyImage
                    sx={{ width: '100%', height: 'auto', mb: '10px' }}
                    alt={card.title}
                    image={image}
                  />
                )}
                <H3 dangerouslySetInnerHTML={{ __html: card.title }} />
                <Paragraph>{card.description}</Paragraph>
              </Box>
            )
          })}
        </Grid>
      </Container>
    </Layout>
  )
}

export default TradeProgramTemplate
